<template lang="pug">
.main-wrapper.bancos-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "bancos" }'> Bancos</router-link> /
                        <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Banco`")
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto; max-width: 500px;')
                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_codigo.$error }")
                            label.form-label Código:
                            InputText(type='text' v-model='$v.model.ds_codigo.$model')
                            .feedback--errors(v-if='submitted && $v.model.ds_codigo.$error')
                                .form-message--error(v-if="!$v.model.ds_codigo.maxLength") <b>Código</b> deve ter no máximo 3 dígitos.
                                .form-message--error(v-if="!$v.model.ds_codigo.required") <b>Código</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nm_banco.$error }")
                            label.form-label Descrição:
                            InputText(type='text' v-model='$v.model.nm_banco.$model')
                            .feedback--errors(v-if='submitted && $v.model.nm_banco.$error')
                                .form-message--error(v-if="!$v.model.nm_banco.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                .form-message--error(v-if="!$v.model.nm_banco.required") <b>Descrição</b> é obrigatório.

                        .p-col-12
                            label.form-label Sigla:
                            InputText(type='text' v-model='model.ds_sigla')

                        .p-col-12.mt-2
                            Button(label='Salvar' type="submit")
</template>

<style lang="scss">
    .bancos-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import InputNumber from 'primevue/inputnumber'
    import InputSwitch from 'primevue/inputswitch'

    import { Bancos } from './../../middleware'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, InputNumber,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, InputSwitch
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true

            let id = null
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id) {
                Bancos.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])
                        
                        this.model.id = id
                    }
                    this.waiting = false
                })
            } else this.waiting = false     
        },
        data () {
            return {
                model: {
                    ds_codigo: null,
                    nm_banco: '',
                    ds_sigla: '',
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nm_banco: { required, minLength: minLength(2) },
                    ds_codigo: { required, maxLength: maxLength(3) },
                }
            }
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                Bancos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$router.push({ name: 'bancos' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>